@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500);
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/*한글에는 다음 폰트 적용.*/
@font-face {
    font-family: "KBO Dia Gothic Light";
    font-weight: 400;
    src: url("./asset/font/KBO Dia Gothic_light.ttf") format("truetype");
    unicode-range: U+AC00-D7A3;
}

/*영어에 적용할 폰트.*/
@font-face {
    font-family: "Pretendard";
    font-weight: 400;
    src: url("./asset/font/PretendardVariable.ttf") format("truetype");
}

.App {
    text-align: center;
    display: block;
    overflow: auto;
    mix-blend-mode: overlay;
}

.ql-video {
    width: 560px;
    height: 315px;
}

/*서비스 전체에 폰트 적용.*/
* {
    font-family: 'KBO Dia Gothic Light', 'Pretendard' !important;
}

.main-logo {
    min-width: 15%;
}

.p-text {
    margin: 0.15px;
    font-size: 14px;
}

.post-form {
    height: fit-content;
    margin-bottom: 12px;
    text-align: initial;
}

.post-form img {
    max-width: 100%;
}

.comment-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.comment-item {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.comment-input-outer {
    width: 100%;
}

.comment-input-textbox {
    height: 10%;
    margin-top: 8px;
    border-radius: 5px;
    padding: 6px;
    border-color: #eeeeee;
    resize: vertical;
}

.comment-input-textbox:focus {
    outline: none;
}

.space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dropdown-wrapper {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 6px 12px;
}

.editor {
    margin-top: 12px;
    padding: 0 0;
    word-break: break-all;
}

.reaction-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.reaction-text {
    font-size: 15px;
    font-weight: bold;
    color: #666666;
}

.reaction-counts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
}

.reaction-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 24px;
    padding: 12px 16px;
}

.reaction-group:hover {
    background-color: #cccccc;
    cursor: pointer;
}

.reaction-group:first-child {
    margin-right: 8px;
}

.reaction-group:last-child {
    margin-left: 8px;
}

.hashtag-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
}

.chip-array {
    line-height: 2.5rem;
    width: 100%;
}

.option-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid #e9e9e9;
    padding: 12px 0;
}

.post-title-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.editor-textarea {
    height: fit-content;
}

.owner-badge {
    margin-left: 12px;
    padding-left: 2px;
    padding-right: 2px;
    border: 1px solid #0084e2;
    border-radius: 6px;
    color: #0084e2;
    background: white;
    font-size: 12px;
}

.col-item:first-child {
    margin-right: 4px;
}

.col-item {
    margin-left: 4px;
    margin-right: 4px;
}

.col-item:last-child {
    margin-left: 4px;
}

.top {
    margin-top: 12px;
}

.post-list-table-page {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.post-list-table {
    overflow: hidden;
    width: 100%;
    border-collapse: collapse;
}

.post-list-header {
    background-color: #ffffff;
    color: #1d2c6b;
    font-size: 14px;
}

.post-list-header-item {
    padding-top: 7px;
    padding-bottom: 7px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    white-space: nowrap;
}

.mobile-post-list {
    width: 100%;
}

.post-list-body {
    width: 100%;
}

.post-list-body-row {
    background-color: #ffffff;
    font-size: 14px;
    border-bottom: 1px solid #eeeeee;
    border-collapse: collapse;
    color: inherit;
    text-decoration: none;
}

.post-list-body-row:hover {
    background-color: #eeeeee;
    border-collapse: collapse;
}

.post-list-body-row-item {
    column-span: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    text-decoration: none;
}

.post-title {
    white-space: nowrap;
    /*overflow: hidden;*/
    text-overflow: ellipsis;
}

.post-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.post-username {
    width: 15%;
}

.post-username:hover {
    cursor: pointer;
    text-decoration: underline;
}

.post-created-at {
    width: 15%;
}

.post-views {
    text-align: center;
    width: 15%;
}

.comments-count {
    margin-left: 8px;
    font-weight: bold;
    font-size: 11px;
    color: #1d2c6b;
}

.mobile-title {
    max-width: 100%;
    font-size: 18px;
    padding-left: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.padding2 {
    padding: 2px;
}

.checkbox {
    text-align: start;
    margin-left: 20px;
}

.centered-item {
    text-align: center;
}

.link {
    text-decoration: none;
}

.padding-input {
    padding: 6px 24px;
}

.text-field {
    display: block;
    box-sizing: content-box;
    margin-bottom: 6px;
    height: 36px;
    border: none;
    background-color: #f8f8f8;
    border-radius: 12px;
    font-weight: 400;
    font-size: 15px;
    transition: 0.2s ease;
    width: 100%;
}

.text-field:focus {
    outline: none;
}

.text-field2 {
    display: block;
    box-sizing: content-box;
    margin-bottom: 6px;
    height: 36px;
    border: none;
    border-bottom: 2px solid #eeeeee;
    font-weight: 400;
    font-size: 15px;
    transition: 0.2s ease;
}

.text-field2:focus {
    outline: none;
    border-bottom: #0084e2;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.link {
    text-decoration: none;
    color: #777777;
}

.link:hover {
    cursor: pointer;
}

.round-button {
    border: none;
    border-radius: 50%;
    padding: 7px 10px;
    background-color: #1d2c6b;
    color: white;
}

.round-button:hover {
    background-color: #1d2c6b;
    cursor: pointer;
}

.button {
    border: none;
    border-radius: 12px;
    padding: 6px 36px;
    background-color: #1d2c6b;
    color: white;
    width: 100%;
}

.button:hover {
    background-color: #1d2c6b;
    cursor: pointer;
}

.button2 {
    border: none;
    border-radius: 8px;
    padding: 6px 6px;
    background-color: #1d2c6b;
    color: white;
}

.button2:hover {
    background-color: #1d2c6b;
    cursor: pointer;
}

.button3 {
    border: none;
    border-radius: 8px;
    padding: 6px 6px;
    width: 100%;
}

.button3:hover {
    cursor: pointer;
}

.button4 {
    border: none;
    border-radius: 8px;
    padding: 6px 6px;
    background-color: #1d2c6b;
    color: #f5f5f5;
}

.button4-reversed {
    border-radius: 8px;
    border: none;
    background-color: #f5f5f5;
    padding: 6px 6px;
    color: #1d2c6b;
}

.button4:hover {
    cursor: pointer;
}

.button4-reversed:hover {
    cursor: pointer;
}

.button5 {
    border: none;
    border-radius: 9px;
    padding: 6px 36px;
    background-color: #1d2c6b;
    color: white;
}

.button5:hover {
    background-color: #3f3f3f;
    cursor: pointer;
}

.btn-default {
    background-color: #1d2c6b;
    color: white;
}

.btn-default:hover {
    background-color: #1d2c6b;
}

.btn-verification {
    border: none;
    border-radius: 8px;
    padding: 6px 6px;
    background-color: #1d2c6b;
    color: white;
}

.btn-verification:hover {
    cursor: pointer;
}

.main-contents {
    padding: 6px 12px;
}

.kakao {
    background-color:  #FEE500;
    color: #000000;
    opacity: 85%;
}

.kakao:hover {
    background-color: #d8c300;
}

.google {
    background-color: white;
    color: #4285F4;
}

.google:hover {
    opacity: 90%;
}

.naver {
    background-color: #03c75a;
    color: white
}

.naver:hover {
    opacity: 90%;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    border: 0;
    border-radius: 3px;
    white-space: normal;
    word-break: break-all;
}

.title:focus {
    outline: none;
}

.terms-and-conditions {
    width: 80%;
    font-size: 1.17rem;
    border: 0;
    border-radius: 3px;
    white-space: normal;
    word-break: break-all;
}

.checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.terms {
    min-width: 24px;
    color: #777777;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.terms:hover {
    cursor: pointer;
    text-decoration: underline;
}

.control-label {
    margin-right: 0;
}

.bold {
    font-weight: bold;
}

.checkbox {
    font-size: 10px;
}

.img-icon {
    display: block;
    background: transparent;
}

.img-icon:hover {
    cursor: pointer;
    background-color: #eeeeee;
}

.horizontal-container {
    display: flex;
    flex-direction: row;
}

.clickable {

}

.clickable:hover {
    cursor:pointer;
}

.align-left {
    justify-content: left;
}

.align-center {
    justify-content: center;
}

.align-right {
    justify-content: right;
}

.horizontal-reverse {
    flex-direction: row-reverse;
}

.reversed {
    transform: scaleX(-1);
}

.modal-image:hover {
    filter: brightness(50%);
}

.mid:hover {
    cursor: pointer;
}

.modal-image {
    width: 100%;
}

.login {
    display: block;
    box-sizing: content-box;
    margin-bottom: 6px;
    padding: 6px 24px;
    height: 36px;
    border: none;
    border-radius: 12px;
    font-size: 15px;
    transition: 0.2s ease;
    width: 100%;
}

.btn-comment {
    border: 1px solid #ffffff;
    background-color: #ffffff;
    color: #1d2c6b;
}

.btn-comment:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.rotate-180 {
    transform: rotate( 180deg );
}

.dropdown-select{
    width: 192px;
    height: 36px;
    color: #777777;
    border-color: #eeeeee;
    border-radius: 1px;
    background-color: #FFFFFF;
    font-size: 15px;
}

.option {
    margin-top: 6px;
    margin-bottom: 6px;
}

.firebrick {
    color: firebrick;
}

.light-grey {
    background-color: #f8f8f8;
}

.font-dark-grey {
    color: #777777;
}

.input {
    display: block;
    box-sizing: content-box;
    margin-bottom: 6px;
    padding: 6px 24px;
    width: 70%;
    height: 36px;
    border: none;
    border-radius: 12px;
    font-size: 15px;
    transition: 0.2s ease;
}

.input:focus {
    outline: none;
}

.input:first-child {
    margin-top: 24px;
}

.input2 {
    display: block;
    box-sizing: content-box;
    padding: 6px 12px;
    height: 36px;
    border:none;
    border-radius: 12px;
    background-color: #f7f7f7;
    font-size: 15px;
    width: 90%;
    transition: 0.2s ease;
}

.input2:focus {
    outline: none;
}

.a {
    margin-top: 24px;
    text-decoration: none;
    color: #777777;
    font-size: 13px;
}

.textarea-agreement {
    position: relative;
    line-height: 1.5;
    margin: 0 20px 5px 20px;
    padding: 10px;
    overflow: auto;
    border: 1px solid #ddd;
    background: #fbfbfb;
    color: #767676;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    font-size: 11.5px;
    text-align: start;
    white-space: pre-line;
    word-break: break-all;
}

.textbox1 {
    width: 100%;
    border-radius: 5px;
    padding: 6px;
    border-color: #eeeeee;
    resize: vertical;
    word-break: break-all;
}

.textbox1:focus {
    outline: none;
}

.fullwidth {
    width: 100%;
}

.pagination {
    margin-top: 0.5%;
    display : flex;
    flex-direction: row;
    justify-content: center;
}

.btn-write-post {
    justify-content: end;
}

.board-item {
    width: 50%;
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: #fefefe;
    display: flex;
    height: fit-content;
    flex-wrap: wrap;
    flex-direction: column;
}

.board-item-header {
    width: 100%;
    background-color: #1d2c6b;
    color: white;
    text-decoration: none;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 4px 0;
}

.board-title {
    font-size: 15px;
    margin: 2px 0;
}

.board-title:hover {
    text-decoration: underline;
}

.subitem-board {
    margin: 4px 0;
}

.subitem-board-title {
    color: #353535;
    font-size: 15px;
    text-decoration: none;
}

.subitem-board-title:hover {
    text-decoration: underline;
}

.vertical-container {
    display: flex;
    flex-direction: column;
}

.grid-2 {
    display: flex;
    column-count: 2;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;
    overflow: auto;
    box-sizing: border-box;
}

.center-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-weight: 700;
    font-size: 24px;
    color: #4e4e4e;
    word-break: break-all;
}

.text {
    width: 80%;
    font-size: 13px;
    color: #4e4e4e;
    word-break: break-all;
}

.profile-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.myaccount-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.side-ad {
    width: 22%;
    padding: 5px;
}

.username {
    font-size: 24px;
    font-weight: 700;
    color: #1d2c6b;
}

.upload-image {
    display: none;
    /*visibility: hidden;*/
}

.profile-image {
    border-radius: 50%;
    object-fit: cover;
}

.profile-image:hover {
    cursor: pointer;
    opacity: 0.8;
}

.bottom {
    position: absolute;
    bottom: 1.5%;
}

.nav-bar {
    cursor: pointer;
    background-color: #1d2c6b;
    color: white;
}

.search-result-list {
    background-color: white;
    padding-top: 10px;
}

.search-result-item {
    margin-bottom: 24px;
}

.search-result-title {
    margin-right: 18%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    font-weight: bold;
    color: #0084e2;
}

.search-result-title:hover {
    background-color: #eeeeee;
}

.matching-keyword {
    color: #2196f3;
}

.search-result-board-and-title {
    display : flex;
    flex-direction: row;
    align-items: center;
}

.password-strength {
    width: 100%;
}

.search-result-board {
    font-size: 10px;
    font-weight: bold;
    background-color: #dedede;
    color: #222222;
    border: none;
    border-radius: 2px;
    height: fit-content;
    padding: 2px;
    margin-right: 8px;
    text-decoration: none;
    text-align: center;
}

.search-result-contents {
    margin-right: 18%;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.thumbnail-img {
    margin-top: 8px;
    max-width: 240px;
    max-height: 480px;
}

.load-more {
    font-size: 14px;
    padding-bottom: 24px;
    cursor: pointer;
}

.load-more:hover {
    color: #0073d1;
}

.result-category-title {
    font-weight: bold;
}

.fontweight-mid {
    font-weight: 500;
}

.font-xs {
    font-size: 9px;
}

.font-sm {
    font-size: 12px;
}

.font-mid {
    font-size: 15px;
}

.font-lg {
    font-size: 18px;
}

.font-xl {
    font-size: 21px;
}

.search {
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.badge-mobile {
    margin-right: 6px;
}

.box {
    width: 100%;
    overflow-x: auto;
    display: flex;
}

.menu-button {
    border: none;
    color: #1d2c6b;
    width: fit-content;
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    padding: 8px;
}

.menu-button:hover {
    cursor: pointer;
    background-color: #eeeeee;
}

.mypage-auth-button {
    border: none;
    color: #1d2c6b;
    width: fit-content;
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    padding: 8px;
}

.mypage-auth-button:hover {
    cursor: pointer;
    background-color: #eeeeee;
}

.upper {
    font-weight: bold;
}

.category-list {
    font-size: 15px;
}

.category-link {
    text-decoration: none;
    color: #1d2c6b;
}

.padding {
    padding-left: 10px;
    padding-right: 10px;
}

.visitor {
    display: flex;
    justify-content: flex-start;
}

.visitor-subitem {
    width: 100%;
    display: flex;
}

.tab1 {
    width: 33%;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
}

.vertical-gap-xs {
    height: 6px;
}

.vertical-gap-sm {
    height: 12px;
}

.vertical-gap-mid {
    height: 24px;
}

.horizontal-margin-xs {
    margin-left: 3px;
    margin-right: 3px;
}

.horizontal-gap-sm {
    width: 12px;
}

.horizontal-gap-mid {
    width: 15px;
}

.toolbar {
    min-height: 0;
    background: #eeeeee;
}

.vt-padding-sm {
    padding-top: 7px;
    padding-bottom: 7px;
}

.vertical-margin {
    margin-top: 12px;
    margin-bottom: 12px;
}

.vertical-center {
    align-items: center;
}

.horizontal-item-first {
    margin-right: 3px;
}

.horizontal-item-mid {
    margin-left: 3px;
    margin-right: 3px;
}

.horizontal-item-end {
    margin-left: 3px;
}

.horizontal-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.fab {
    position: fixed;
    width: 50px;
    height: 50px;
    border: none;
    border-width: 1px;
    border-radius: 10px;
    bottom: 5%;
    right: 5%;
    background-color: #f5f5f5;
}

.fab:hover {
    cursor: pointer;
    box-shadow: 2px 2px 0 0 #e4e4e4;
}

.post-search {
    min-width: 240px;
    color: #777777;
    border: 1px solid #eeeeee;
    padding-top: 3px;
    padding-bottom: 3px;
    outline: none;
}

.icon-wrapper {
    padding: 2px 5px;
    border-radius: 5px;
    border: none;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon-wrapper:hover {
    cursor: pointer;
    background-color: #e4e4e4;
    box-shadow: 1px 1px 0 0 #c3c3c3;
}

.pd-sm {
    padding-left: 3px;
    padding-right: 3px;
}

.pd-mid {
    padding: 2%;
}

.ad1-box {
    width: 80%;
    height: 20%;
    background-color: firebrick;
    visibility: hidden;
}

.font-light-grey {
    color: #aeaeae;
}

.bg-light-grey {
    background-color: #f5f5f5;
}

.vertical-padding-sm {
    padding-top: 24px;
    padding-bottom: 48px;
}

.textarea-wrapper {
    display: block;
    box-sizing: content-box;
}

.textarea-comment {
    min-height: 72px;
    max-height: 108px;
    width: 95%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    transition: 0.2s ease;
    resize: none;
    word-break: break-all;
    outline: none;
}

.textarea:hover {
    outline: none;
}

.signup-input-row {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .num-comments {
        font-size: 12px;
        margin-left: 6px;
    }

    .button4 {
        width: 150px;
        height: 36px;
    }

    .button4-reversed {
        width: 150px;
        height: 36px;
    }

    .button5 {
        width: 150px;
        height: 36px;
    }

    .xs {
        width: 12px;
        height: 12px;
    }

    .sm {
        width: 15px;
        height: 15px;
    }

    .mid {
        width: 21px;
        height: 21px;
    }

    .large {
        width: 24px;
        height: 24px;
    }

    .main-contents {
        width: 95%;
        align-items: center;
    }

    .post-list-body-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #333333;
    }

    .post-title-and-comments {
        display: flex;
        width: 100%;
        margin-top: 10px;
        flex-direction: row;
        align-items: center;
    }

    .post-view-title {
        max-width: 80%;
        padding-left: 6px;
        /*overflow: hidden;*/
        margin-bottom: 2px;
    }

    .mobile-post-info {
        padding-left: 2%;
        display: flex;
        flex-direction: row;
        font-size: 10px;
        margin-top: 2px;
        margin-bottom: 10px;
    }

    .post-form {
        width: 100%;
    }

    .comment-box {
        width: 100%;
    }

    .comment-input-outer {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .comment-input-textbox {
        width: 95%;
    }

    .input {
        width: auto;
        padding: 6px 12px;
    }

    .textfield_signup {
        padding: 6px 12px;
    }

    .textarea-agreement {
        height: 140px;
    }

    .myaccount-layout {
        flex-direction: column;
    }

    .profile-image {
        width: 64px;
        height: 64px;
    }

    .profile-image-wrapper {
        padding: 12px 0 12px 0;
        flex-direction: column;
    }

    .nav-bar {
        font-size: 36px;
        font-weight: 700;
        padding: 6px 0 0 12px;
    }

    .search-result-board {
        min-width: 13%;
    }

    .PrimaryHeader {
        top: 5.5%;
        right: 11.5%;
        bottom: 3%;
        left: 11.5%;
        margin: 0;
    }

    .toolbar {
        flex-direction: column;
    }

    .box {

    }

    .menu-button {
        background-color: white;
    }

    .category-list {
        background-color: white;
    }

    .visitor {
        flex-direction: row;
        justify-content: center;
    }

    .visitor-subitem {
        flex-direction: row;
    }

    .subitem-gap {
        margin-left: 6px;
    }

    .post {
        padding: 6px 12px;
    }

    .button-group1 {
        width: 80%;
    }

    .grid-2 {
        height: 1600px;
    }

    .signup-email {
        width: 60%;
    }

    .signup-email-verification-code {
        width: 50%;
    }

    .btn-verification {
        font-size: 10px;
    }

    .select-team-logo {
        height: 18px;
    }

    .form {
        width: 80%;
    }

    .ql-video {
        width: 360px;
        height: 240px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .button4 {
        width: 200px;
        height: 40px;
    }

    .button4-reversed {
        width: 200px;
        height: 40px;
    }

    .button5 {
        width: 150px;
        height: 36px;
    }

    .num-comments {
        font-size: 13px;
        margin-left: 8px;
    }

    .xs {
        width: 12px;
        height: 12px;
    }

    .sm {
        width: 15px;
        height: 15px;
    }

    .mid {
        width: 21px;
        height: 24px;
    }

    .large {
        width: 27px;
        height: 27px;
    }

    .main-contents {
        width: 85%;
        align-items: center;
    }

    .post-view-title {
        max-width: 150px;
    }

    .post-form {
        width: 95%;
    }

    .comment-box {
        width: 95%;
    }

    .comment-input-textbox {
        width: 95%;
    }

    .textarea-agreement {
        height: 280px;
    }

    .myaccount-layout {
        flex-direction: column;
    }

    .profile-image {
        width: 96px;
        height: 96px;
    }

    .profile-image-wrapper {
        padding: 24px 0;
        flex-direction: column;
    }

    .username {
        padding: 24px 0 0 0;
    }

    .PrimaryHeader {
        top: 5.5%;
        right: 11.5%;
        bottom: 3%;
        left: 11.5%;
        margin: 0;
    }

    .visitor {
        flex-direction: column;
    }

    .visitor-subitem {
        flex-direction: row;
    }

    .toolbar {
        display: flex;
        justify-content: center;
    }

    .subitem-gap {
        margin-top: 6px;
    }

    .post {
        padding: 6px;
    }

    .button-group1 {
        width: 65%;
    }

    .grid-2 {
        width: 85%;
        height: 100vh;
    }

    .signup-email {
        width: 50%;
    }

    .signup-email-verification-code {
        width: 50%;
    }

    .select-team-logo {
        height: 21px;
    }

    .form {
        width: 50%;
    }
}

@media screen and (min-width: 1024px) {
    .num-comments {
        font-size: 13px;
        margin-left: 9px;
    }

    .xs {
        width: 12px;
        height: 12px;
    }

    .sm {
        width: 18px;
        height: 18px;
    }

    .mid {
        width: 21px;
        height: 21px;
    }

    .large {
        width: 48px;
        height: 48px;
    }

    .main-contents {
        width: 56%;
        align-items: center;
    }

    .button4 {
        width: 240px;
        height: 40px;
    }

    .button4-reversed {
        width: 240px;
        height: 40px;
    }

    .button5 {
        width: 150px;
        height: 36px;
    }

    .post-view-title {
        max-width: 540px;
        margin-right: 36px;
    }

    .post-form {
        width: 95%;
    }

    .comment-box {
        width: 95%;
    }

    .comment-input-textbox {
        width: 95%;
    }

    .input {
        width: 30%;
    }

    .textfield_signup:first-child {
        margin-top: 48px;
    }

    .textfield_signup {
        width: 30%;
    }

    .textarea-agreement {
        height: 360px;
    }

    .text {
        width: auto;
    }

    .profile-image {
        width: 128px;
        height: 128px;
    }

    .username {
        padding: 24px 0 0 0;
    }

    .profile-image-wrapper {
        padding: 24px 0;
        flex-direction: column;
    }

    .PrimaryHeader {
        top: 5.5%;
        right: 11.5%;
        bottom: 3%;
        left: 11.5%;
        margin: 0;
    }

    .visitor {
        flex-direction: column;
    }

    .visitor-subitem {
        flex-direction: row;
    }

    .toolbar {
        display: flex;
        justify-content: center;
    }

    .subitem-gap {
        margin-top: 6px;
        margin-left: 6px;
    }

    .top {
        margin-bottom: 12px;
    }

    .post {
        padding: 6px;
    }

    .button-group1 {
        width: 65%;
    }

    .grid-2 {
        width: 56%;
        height: 1200px;
    }

    .signup-email {
        width: 55%;
    }

    .signup-email-verification-code {
        width: 55%;
    }

    .select-team-logo {
        height: 24px;
    }

    .form {
        width: 50%;
    }
}